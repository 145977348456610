.main-container {
  display: flex;
  align-items: flex-start;
  height: 100vh;
}

.sidebar-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 10px;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  color: var(--secondary-text-color);
  box-sizing: border-box;
  height: 100vh;
  overflow-y: scroll;
  min-width: 280px;
  max-width: 280px;
  background-color: #333333;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #e1e1e11a;
    border-radius: 5px;
  }
}

.logo-container {
  height: 84px;
  display: flex;
  align-items: center;
  padding-left: 26px;
}

.logo {
  color: var(--secondary-text-color);
  font-weight: 500;
  font-size: 24px;
  line-height: 1.286;
}

.create-button-container {
  display: flex;
  justify-content: center;
  padding-top: 1em;
}

.sidebar-app-version {
  color: var(--secondary-text-color);
  margin-right: 20px;
  align-self: end;
  font-size: 14px;
}

.nav-buttons-list {
  &-block {
    padding-top: 30px;
    border-top: 1px solid #606060;
    padding-bottom: 30px;
  }
  &-block > .navbar-link:not(:last-child) {
    margin-bottom: 16px;
  }
}

.accordion-list > .navbar-link {
  margin: 0 0 16px 20px;
}

.nav-button {
  color: var(--secondary-text-color);
  width: 100%;
  height: 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  transition: color 250ms linear;
  &:hover {
    color: #a1a1a1;
  }
  &-text-container {
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.icon-container {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-link {
  display: block;
  padding-left: 26px;
  padding-right: 26px;
}

.icon {
  fill: var(--secondary-text-color);
  flex-shrink: 0;
}

.current-page {
  color: #ffffff;
  font-weight: 700;
  & .icon {
    fill: #ffffff;
  }
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-alert-container {
    background-color: white;
    width: 100%;
    max-width: 300px;
    padding: 1em;
    border-radius: 10px;
    overflow-y: auto;
    .modal-head-container {
      display: flex;
      justify-content: space-between;
    }
  }
  .modal-container {
    background-color: white;
    width: 100%;
    max-width: 600px;
    max-height: 700px;
    padding: 1em;
    border-radius: 10px;
    overflow-y: auto;
    .modal-head-container {
      display: flex;
      justify-content: space-between;
    }
  }
  .modal-container-vacancies {
    background-color: white;
    width: 100%;
    max-width: 900px;
    max-height: 800px;
    padding: 1em;
    border-radius: 10px;
    overflow-y: auto;
    .modal-head-container {
      display: flex;
      justify-content: space-between;
    }
  }

  .reset-password-container {
    @extend .modal-container;
    width: 450px;
  }
}

.create-user-button {
  border: 1px solid black;
  background-color: white;
  padding: 8px 16px;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  transition: 0.5s;

  &:hover {
    background-color: black;
    color: white;
  }
}

.link-menu-HR {
  border-top: 1px solid #606060;
  padding: 20px 20px 20px 26px;
}

.HR-link,
.report-link {
  padding-left: 0;
  padding-right: 0;
  &-reminders {
    margin-left: -3px;
  }
  &-vacancies {
    margin-left: -2px;
  }
  &-candidates {
    margin-left: 4px;
  }
}
